import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { SupplierDetailComponent } from './views/settings/suppliers/supplier-detail/supplier-detail.component';
//import { AdminDashboardComponent } from './views/dashboard/admin/admin-dashboard/admin-dashboard.component';

import { AppComponent } from './app.component';
export const rootRouterConfig: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "supplier-detail",
        component: SupplierDetailComponent,
        outlet: "form-outlet",
        canActivate: [AuthGuard],
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'DASHBOARD' }
      },
      {
        path: 'supplier',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/supplier/supplier.module').then(m => m.SupplierModule),
        data: { title: 'Supplier', breadcrumb: 'SUPPLIER' }
      },
      {
        path: 'search',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/search/search.module').then(m => m.SearchModule),
        data: { title: 'Search', breadcrumb: 'Search' }
      },
      {
        path: 'environmental',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/environmental/environmental.module').then(m => m.EnvironmentalModule),
        data: { title: 'Environmental', breadcrumb: 'environmental' }
      },
      {
        path: 'social',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/social/social.module').then(m => m.socialDashboardModule),
        data: { title: 'Social', breadcrumb: 'social' }
      },
      {
        path: 'administration',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/administration/administration.module').then(m => m.AdministrationModule),
        data: { title: 'Administration', breadcrumb: 'administration' }
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
        data: { title: 'Settings', breadcrumb: 'settings' }
      },
      {
        path: 'dataManagement',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/settings/data-management.module').then(m => m.DataManagementModule),
        data: { title: 'Settings', breadcrumb: 'settings' }
      },
      {
        path: 'dashboard/admin',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/dashboard/admin/admin.module').then(m => m.AdminModule),
        data: { title: 'dashboard', breadcrumb: 'dashboard' }
      },
      {
        path: 'operations',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/operations/operations.module').then(m => m.OperationsModule),
        data: { title: 'Settings', breadcrumb: 'settings' }
      },
    ]
  },
  {
    path: '',
    component: AppComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule),
        data: { title: 'Session' }
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'Dashboard' }
      },
      {
        path: 'emissions',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/environmental/emissions/emissions.module').then(m => m.EmissionsModule),
        data: { title: 'Emissions', breadcrumb: 'Emissions' }
      },
      {
        path: 'administration',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/administration/administration.module').then(m => m.AdministrationModule),
        data: { title: 'Administration', breadcrumb: 'Administration' }
      },
      {
        path: 'social-dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/social/social-dashboard/social-dashboard.module').then(m => m.socialDashboardModule),
        data: { title: 'social-dashboard', breadcrumb: 'social-dashboard' }
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
        data: { title: 'Settings', breadcrumb: 'settings' }
      },
      {
        path: 'dataManagement',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/settings/data-management.module').then(m => m.DataManagementModule),
        data: { title: 'Settings', breadcrumb: 'settings' }
      },

      {
        path: 'dashboard/admin',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/dashboard/admin/admin.module').then(m => m.AdminModule),
        data: { title: 'dashboard', breadcrumb: 'dashboard' }
      },
      //{ path: '', redirectTo: 'dashboard/admin', pathMatch: 'full' },  // Default route
      //{ path: 'dashboard/admin', loadChildren: () => import('./views/dashboard/admin/admin.module').then(m => m.AdminModule) },
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

