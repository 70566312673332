import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMenuItem } from '../../services/navigation.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/views/settings/settings.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html'
})
export class SidenavComponent {
  @Input('items') public menuItems: IMenuItem[] = [];
  @Input('hasIconMenu')
  public hasIconTypeMenuItem!: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle!: string;
  @Output() menuClick: EventEmitter<any> = new EventEmitter<any>();
  isWarningNotification: boolean = false;
  isForceNotification: boolean = false;
  mailCount!:any[];

  constructor(private cookieService: CookieService,private router: Router, private settingsService: SettingsService,) {
    this.isWarningNotification = this.cookieService.check("isWarningNotification") ? JSON.parse(this.cookieService.get("isWarningNotification")) : false;
    this.isForceNotification = this.cookieService.check("isForceNotification") ? JSON.parse(this.cookieService.get("isForceNotification")) : false;
    this.addMenuItem();
  }
  
  // Only for demo purpose
  async addMenuItem() {
    this.menuItems.push({
      name: 'ITEM',
      type: 'dropDown',
      tooltip: 'Item',
      icon: 'done',
      state: 'material',
      sub: [
        { name: 'SUBITEM', state: 'cards' },
        { name: 'SUBITEM', state: 'buttons' }
      ],
      action: null
    });

    this.mailCount = await this.settingsService.getUnreadMailCountByUserId()
    console.log(this.mailCount,'this.mailCount')
  }

  onMenuClick(menu:IMenuItem){
    this.menuClick.emit(menu)
    if (menu.sub) {
      this.router.navigate([`/${menu.state}`]);
    } else {
      this.router.navigate([`/${menu.state}`]);
    }
  }
}